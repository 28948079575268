import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store/index.js';
import { getSisStatus, getSisPlanoDebito } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { PostPlanoContasDelAPI, GetPlanoContasIdAPI, PostPlanoContasAPI } from '../models/ModelPlanoContas.js';

class PlanoContasUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().planoContas.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    if (this.state.refresh === 'excluir') {
      this.callApiExcluir();
      this.setState({
        refresh: 'excluindo'
      })
    }

    var response = {}
    if (store.getState().planoContas.cadastro.refresh === 'buscando' &&
      store.getState().planoContas.cadastro.id !== store.getState().planoContas.cadastro.id_emp_planoconta_item
    ) {
      response = { type: 'PLANOCONTAS.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetPlanoContas(store.getState().planoContas.cadastro.id)
    }

    if (store.getState().planoContas.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'PLANOCONTAS.TOOGLE_CLEAN' }
      this.updateRedux(response);
      var cadastro = store.getState().planoContas.cadastro;
      this.setState(cadastro);
    }
  }


  async callApi() {

    let data =
    {
      id_emp_planoconta: this.state.id_emp_planoconta,
      id_sis_status: this.state.id_sis_status,
      plano_conta_descricao: this.state.plano_conta_descricao,
      exibe_pdv: this.state.exibe_pdv,      
    }

    var response = await PostPlanoContasAPI(this.state.id_emp_planoconta_item, data);

    if (this.state.id_emp_planoconta_item === 0 && response.erro === 0) {
      response.type = 'PLANOCONTAS.TOOGLE_CLEAN';
      this.updateRedux(response);
      response = store.getState().planoContas.cadastro;
    }
    this.setState(response);

  }

  async callApiExcluir() {

    var response = await PostPlanoContasDelAPI(this.state.id_emp_planoconta_item);

    if (response.erro === 0) {
      response.type = 'PLANOCONTAS.TOOGLE_CLEAN';
      response.refresh = 'concluido';
      this.updateReduxAndState(response);
      var cadastro = store.getState().planoContas.cadastro;
      this.setState(cadastro);
      this.actionClick('.lista');
      this.clearModal();
    }


  }

  async callApiGetPlanoContas(id) {

    var response = await GetPlanoContasIdAPI(id);

    response.refresh = 'concluido';
    if (response.erro === 0)
      response.type = 'PLANOCONTAS.TOOGLE_GET_ID';
    else
      response.type = 'PLANOCONTAS.TOOGLE_CLEAN';


    this.updateRedux(response);
    var cadastro = store.getState().planoContas.cadastro;
    //cadastro.plano_debito = getSisPlanoItemDebito(response.id_emp_planoconta);

    this.setState(cadastro);

  }

  handleExcluir = (event) => {
    event.preventDefault()

    this.setState({ refresh: 'excluir' });

  }

  render() {
    return (
      <form name="form" onSubmit={this.handleSubmitGravando} action="" method="get" encType="multipart/form-data">

        {<FormEdit id='id_emp_planoconta'
          label='Plano de Contas'
          styleDiv='2'
          typeField='select'
          value={this.state.id_emp_planoconta}
          onChange={this.handleInputChange}
          required='on'
          optionsField={getSisPlanoDebito()}
        />}


        {<FormEdit id='plano_conta_descricao'
          label='Descrição'
          styleDiv='2'
          onChange={this.handleInputChange}
          required='on'
          value={this.state.plano_conta_descricao}
        />}

        {<FormEdit id='id_sis_status'
          label='Status'
          styleDiv='4'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_sis_status}
          required='on'
          optionsField={getSisStatus('status_padrao')}
        />}
        
        {<FormEdit id='exibe_pdv'
          label='Exibe PDV'
          styleDiv='4'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.exibe_pdv}
          required='on'
          optionsField={getSisStatus('status_pdv')}
        />}        
        
        <div className="col s12">
          {<FormEdit id='excluir'
            label='Excluir'
            styleDiv='submit right'
            styleField='submit'
            typeField='button'
            onClick={this.handleExcluir}
            icon='excluir'
          />}

          {<FormEdit id='gravar'
            label='Gravar'
            styleDiv='submit right'
            styleField='submit'
            typeField='submit'
            icon='gravar'
          />}
        </div>
      </form>

    );

  }
}


export default PlanoContasUpdate;
