import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';


/**
 * Lista de Plano de Contas
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome  
 */
 
export const GetPlanoContasAPI = (limit, offset, fNome) => {

  progressBar();


  return api.get('/planocontas/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome )
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}



/* 
 * Excluir Plano de Contas
 * @param {*} id  
 */
export const PostPlanoContasDelAPI = (id) => {
  progressBar();

  var url = '/planocontas/del/' + id;

  return api.get(url)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}

/* 
 * Busca Plano de Contas
 * @param {*} id  
 */
export const GetPlanoContasIdAPI = (id) => {
  progressBar();
  return api.get('/planocontas/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_emp_planoconta_item = id;
      ret.refresh =  'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro de Plano de Contas
 * @param {*} id 
 * @param {*} data 
 */
export const PostPlanoContasAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === 0) {
    url = '/planocontas/add';
  } else {
    url = '/planocontas/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
