import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store/index.js';

import FormEdit from '../components/FormEdit.js';
import { GetPlanoContasAPI } from '../models/ModelPlanoContas.js';
import PlanoContasUpdate from './PlanoContasUpdate.js';


class PlanoContas extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().planoContas.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()

    var response = {
      refresh: 'gravando',
      fNome: this.state.fNome,
      type: 'PLANOCONTAS.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  novoCadastro = (event) => {
    event.preventDefault()

    var response = { type: 'PLANOCONTAS.TOOGLE_CLEAN', refresh: 'limpando' }
    this.updateReduxAndState(response);

    this.actionClick('.cadastro');
    this.clearModal();
  }


  clickChildCadastro = (event, id) => {
    event.preventDefault()

    var response = {
      id: id,
      type: 'PLANOCONTAS.TOOGLE_CADASTRO',
      refresh: 'buscaplanocontas'
    }

    this.updateReduxAndState(response);

    this.actionClick('.cadastro');
    this.clearModal();

  }

  async callApi() {
    var grids = store.getState().planoContas.grids;
    var response = await GetPlanoContasAPI(grids.limit, grids.offset, grids.fNome);
    response.refresh = 'concluido';
    response.onClickModal = this.clickChildCadastro;
    response.callBackPaginacao = this.callBackPaginacao;
    response.type = 'PLANOCONTAS.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().planoContas.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Plano de Contas</h2>
          <div className="card">
            {this.navMenu(store.getState().planoContas.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fNome'
                  label='Busque por nome'
                  value={this.state.fNome}
                  onChange={this.handleInputChange}
                  styleDiv='4' />}


                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

                {<FormEdit id='cadNovo'
                  label='Novo Cadastro'
                  styleDiv='submit'
                  onClick={this.novoCadastro}
                  styleField='submit'
                  typeField='button'
                  icon='adiciona'
                />}

              </form>

              {this.Grid(store.getState().planoContas.grids)}

              
            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<PlanoContasUpdate />}
            </div>
          </div>
        </div>
      </div>

    );

  }
}
export default (PlanoContas);
