import { combineReducers } from 'redux';

import clientes from './clientes.js'
import fornecedores from './fornecedores.js'
import produtos from './produtos.js'
import servicos from './servicos.js'
import planoContas from './planoContas.js'
import contasPagar from './contasPagar.js'
import contasReceber from './contasReceber.js'
import colaboradores from './colaboradores.js'
import comandas from './comandas.js'
import fluxoCaixa from './fluxoCaixa.js'
import resultadoFinanceiro from './resultadoFinanceiro.js'
import relContasPagarGeral from './relContasPagarGeral.js'
export default combineReducers({
    clientes, fornecedores, produtos, servicos, colaboradores, comandas, contasPagar, contasReceber,
    fluxoCaixa, relContasPagarGeral, resultadoFinanceiro, planoContas
})