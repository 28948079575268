const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'PLANOCONTAS.',
        header: ['ID', 'Plano de Contas', 'Descrição'],
        colunas: ['id_emp_planoconta_item', 'conta', 'plano_conta_descricao'],
        checkbox: '',
        styles: ['50px','150px', '150px'],
        lista: [],

        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        endereco: null,
        nav: {
            tabs: ['Lista de plano de contas', 'Cadastrar'],
            className: ['lista active', 'cadastro'],
            disabled: [null, null],
            href: ['#lista', '#cadastro'],
            onClick: [null, null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        id_emp_planoconta_item: 0,
        id_emp_planoconta: '',
        id_sis_status: '',
        plano_conta_descricao: '',
        exibe_pdv: ''
    }

}

export default function planoContas(state = INITIAL_STATE, action) {

    var grid = state.grids;
    if (action.type === 'PLANOCONTAS.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }

    if (action.type === 'PLANOCONTAS.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fDataIni = action.fDataIni;
        grid.fDataFim = action.fDataFim;
        grid.fDataTipo = action.fDataTipo;
        return { ...state, grid }
    }

    if (action.type === 'PLANOCONTAS.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }

    if (action.type === 'PLANOCONTAS.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    var cadastro = state.cadastro;
    if (action.type === 'PLANOCONTAS.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        return { ...state, cadastro }
    }

    if (action.type === 'PLANOCONTAS.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }

    if (action.type === 'PLANOCONTAS.TOOGLE_SET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id_emp_planoconta_item = action.id_emp_planoconta_item;
        cadastro.id_emp_planoconta = '';
        cadastro.plano_conta_descricao = '';
        cadastro.exibe_pdv = '';
        return { ...state, cadastro }
    }

    if (action.type === 'PLANOCONTAS.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id_emp_planoconta_item = action.id_emp_planoconta_item;
        cadastro.id_emp_planoconta = action.id_emp_planoconta;
        cadastro.id_sis_status = action.id_sis_status;
        cadastro.plano_conta_descricao = action.plano_conta_descricao;
        cadastro.exibe_pdv = action.exibe_pdv;

        return { ...state, cadastro }
    }


    if (action.type === 'PLANOCONTAS.TOOGLE_CLEAN') {

        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.id_emp_planoconta = '';
        cadastro.id_emp_planoconta_item = 0;
        cadastro.id_sis_status = '';
        cadastro.plano_conta_descricao = '';
        cadastro.exibe_pdv = '';
        return { ...state, cadastro }
    }


    return state;
}