import axios from "axios";
import { getToken } from "./Auth";

const api = axios.create({
  //baseURL: "https://tec2web.com.br/api" //para outros clientes

  baseURL: "https://tec2web.com.br/api/saulo" 
  });


api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = 'Bearer '+token;
  }
  //console.log('corsOk');
  config.headers.Origin = 'https://granja.tec2web.com.br';
  return config;
});


export default api;